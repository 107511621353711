<template>
  <div class="admin-layout d-flex align-stretch w-full">
    <v-app-bar
      class="phone-header d-block d-md-none gradient-bg"
      absolute
      flat
      dark
    >
      <v-icon @click="toHome()">mdi-chevron-left</v-icon>
      <v-spacer></v-spacer>
      {{ $t($route.name) }}
      <v-spacer></v-spacer>
      <v-icon @click="navShow=true">mdi-menu</v-icon>
    </v-app-bar>
    <v-navigation-drawer absolute touchless v-model="navShow" :permanent="$store.state.nowWidth>=960" :temporary="$store.state.nowWidth<960" class="flow-bg admin-sidebar white--text h-full" width="326">
      <div class="d-flex align-center ma-10">
        <img class="mr-5" src="@/assets/img/icon-admin.svg" alt="admin-logo" width="68px">
        <div>
          <div class="rem-12">TBT SHOP</div>
          <div class="rem-6">{{ $t('adminManagement') }}</div>
        </div>
      </div>

      <v-btn block tile dark solo depressed color="rgba(0, 0, 0, 0)" active-class="active-item" class="py-7 pl-10">{{ $t('adminInfo') }}</v-btn>
      <v-btn block tile dark solo depressed color="rgba(0, 0, 0, 0)" active-class="active-item" class="py-7 pl-10" :to="{name: 'admin-confirm', params: {lang: $store.state.locale}}">{{ $t('admin-confirm') }}</v-btn>
      <v-btn block tile dark solo depressed color="rgba(0, 0, 0, 0)" active-class="active-item" class="py-7 pl-10" :to="{name: 'withdraw-tbt', params: {lang: $store.state.locale}}">{{ $t('withdrawTbt') }}</v-btn>
    </v-navigation-drawer>
    <div class="admin-main bg-light-gray w-full pb-10">
      <div class="d-none d-md-block admin-account-block text-right rem-2 pa-9 white">{{ $t('adminAccount') }}：0{{ $store.state.userInfo.phone_number }}</div>
      <router-view />
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      navShow: this.$store.state.nowWidth>=960,
    }
  },
  methods:{
    toHome(){
      this.$router.push({name: "Home", params: {lang: this.$store.state.locale}})
    }
  },
  created(){
    if (this.$store.state.userInfo.identity!==3){
      this.$router.push({name: 'Home', params:{lang: this.$store.state.locale}})
    }
  },
  mounted(){
    
  }
}
</script>
<style lang="scss">
.admin-layout{
  min-height: 100vh;
  height: 100%;
  .admin-sidebar{
    min-height: 100vh;
    .v-btn{
      justify-content: flex-start;
    }
    .active-item{
      background-color: rgba(255, 255, 255, .2);
    }
  }
  .admin-main{
    margin-left: 326px;
    @include dai_vuetify_md{
      margin-left: 0;
      margin-top: 56px;
    }
    .admin-account-block{
      color: #6285A7;
    }
  }
}

</style>